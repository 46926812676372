import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  public message: string;
  public confirmText: string;
  public cancelText: string;
  public title: string;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.message = this.data.message;
    this.confirmText = this.data.confirmText;
    this.cancelText = this.data.cancelText;
    this.title = this.data.title;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }
}
