import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-ie-warning',
  templateUrl: './i-e-warning.component.html',
  styleUrls: ['./i-e-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IEWarningComponent {

  constructor(public ref: MatBottomSheetRef<IEWarningComponent>) {
  }


}
