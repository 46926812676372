/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookedUserPackageDiscount } from './bookedUserPackageDiscount';
import { UserConstraints } from './userConstraints';

export interface UserPackage { 
    actualPriceCents?: number;
    billingType?: UserPackage.BillingTypeEnum;
    bookable?: boolean;
    constraints?: UserConstraints;
    created?: Date;
    discounts?: Array<BookedUserPackageDiscount>;
    displayName?: string;
    durationDays?: number;
    eTag?: string;
    groupName?: string;
    id?: number;
    identifier?: string;
    name?: string;
    originalPriceCents?: number;
    updated?: Date;
    version?: number;
}
export namespace UserPackage {
    export type BillingTypeEnum = 'MONTHLY' | 'ONCE';
    export const BillingTypeEnum = {
        MONTHLY: 'MONTHLY' as BillingTypeEnum,
        ONCE: 'ONCE' as BillingTypeEnum
    };
}