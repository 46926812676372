export namespace CustomersOverview {
  export class FetchCustomersOfTeam {
    static readonly type = '[Customers Overview] Fetch Customers Of Team';
    constructor(public teamId: number) {}
  }

  export class TicketForCustomerCreated {
    static readonly type = '[Customers Overview] Ticket For Customer Created';
    constructor(public customerId: number) {}
  }
}
