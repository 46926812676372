import {Injectable} from '@angular/core';
import {TourService as Tour} from 'ngx-tour-md-menu';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  private _isTourEnabled = false;

  private startingStep: string | undefined;

  constructor(private tour: Tour) {
  }

  get isTourEnabled(): boolean {
    return this._isTourEnabled;
  }

  disableTour(): void {
    this._isTourEnabled = false;
    this.startingStep = undefined;
  }

  enableTourForCurrentPage(): void {
    this._isTourEnabled = true;
  }

  startAt(step: string): void {
    this.startingStep = step;
  }

  start(): void {
    if (this._isTourEnabled) {
      this.tour.startAt(this.startingStep);
    }
  }

}
