import {Injectable} from '@angular/core';
import {Session} from '@api';

@Injectable()
export class UserSessionService {

  public session: Session;

  constructor() { }

}
