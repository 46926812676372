import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import packageInfo from '../../../../package.json';
import {MatDialog} from '@angular/material/dialog';
import {ChangelogDialogComponent} from '../changelog-dialog/changelog-dialog.component';
import {UserService} from '../user.service';
import {SessionInformation} from '@api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  public readonly version: string = packageInfo.version;

  public isLoggedIn$: Observable<boolean>;

  constructor(private dialog: MatDialog,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.userService.sessionInformation.pipe(
      map((session: SessionInformation | null) => session !== null)
    );
  }

  showReleaseNotes(): void {
    this.dialog.open(ChangelogDialogComponent);
  }

}
