import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private lastNotificationDisplayedAt: moment.Moment;
  private readonly displayThresholdInMilliSecs = 1000;

  constructor(private snackBar: MatSnackBar,
              private zone: NgZone) {
  }

  displayNotification(msg: string, duration = 1500, isError = false): void {
    if (
      this.lastNotificationDisplayedAt === undefined
      || moment().diff(this.lastNotificationDisplayedAt) > this.displayThresholdInMilliSecs
    ) {
      this.zone.run(() => {
        this.snackBar.open(msg, '', {
          duration,
          panelClass: isError ? 'snackbar-error' : []
        });
      });

      this.lastNotificationDisplayedAt = moment();
    }
  }

}
