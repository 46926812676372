import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {SpinnerService} from './spinner/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  private readonly ignoredURLs = ['addAttachment', 'addCustomerUpload'];

  constructor(private spinnerService: SpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignore = this.ignoredURLs.find(url => request.url.includes(url)) !== undefined;
    if (ignore) {
      return next.handle(request);
    }

    const spinner = this.spinnerService.showSpinner(`HTTP ${request.method} ${request.url}`);

    return next.handle(request).pipe(
      finalize(() => this.spinnerService.hideSpinner(spinner))
    );
  }
}
