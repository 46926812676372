/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FieldModification { 
    fieldName: string;
    operation: FieldModification.OperationEnum;
    value?: any;
}
export namespace FieldModification {
    export type OperationEnum = 'DELETE' | 'REPLACE' | 'APPEND' | 'REMOVE';
    export const OperationEnum = {
        DELETE: 'DELETE' as OperationEnum,
        REPLACE: 'REPLACE' as OperationEnum,
        APPEND: 'APPEND' as OperationEnum,
        REMOVE: 'REMOVE' as OperationEnum
    };
}