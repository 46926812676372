import {Customer} from '@api';

export namespace CustomerApi {
  export class CreateSuccess {
    static readonly type = '[Customer API] Create Success';
    constructor(public createdEntity: Customer) {}
  }

  export class UpdateSuccess {
    static readonly type = '[Customer API] Update Success';
    constructor(public updatedEntity: Customer) {}
  }

  export class DeleteSuccess {
    static readonly type = '[Customer API] Delete Success';
    constructor(public deletedEntityId: number) {}
  }

  export class CustomerNotFound {
    static readonly type = '[Customer API] Customer Not Found';
    constructor(public customerId: number) {}
  }
}
