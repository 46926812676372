import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app-routing';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppCommonModule} from '@common/app-common.module';
import {MaterialDesignModule} from './material-design.module';
import {ApplicationConfiguration} from '@common/application-configuration';
import {AuthInterceptor} from '@common/auth.interceptor';
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {GlobalErrorHandler} from './global-error-handler';
import {TourMatMenuModule} from 'ngx-tour-md-menu';
import {ApiModule, Configuration} from '@api';
import {SpinnerInterceptor} from '@common/spinner.interceptor';
import {DeviceDetectorModule} from 'ngx-device-detector';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../environments/environment';
import {CustomerOverviewState} from './shared/state/state/customer-overview.state';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {germanPaginator} from './paginator-intl';
import {MessagingState} from './shared/state/state/messaging.state';

registerLocaleData(localeDe, localeDeExtra);

declare const applicationConfiguration: ApplicationConfiguration;

export function configurationGetter() {
  return new Configuration({
    apiKeys: {},
    basePath: applicationConfiguration.backendUrl
  });
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppCommonModule.forRoot(),
    ApiModule.forRoot(configurationGetter),
    MaterialDesignModule,
    TourMatMenuModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DeviceDetectorModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      enableSourceMaps: true
    }),
    NgxsModule.forRoot([
      CustomerOverviewState,
      MessagingState,
    ], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: applicationConfiguration.baseHref || '/'
    },
    {
      provide: 'domain',
      useValue: applicationConfiguration.backendUrl || '/'
    },
    {
      provide: 'isBeta',
      useValue: applicationConfiguration.isBeta || false
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: 'origin',
      useValue: window.location.origin
    },
    {
      provide: 'extras',
      useValue: applicationConfiguration.extras || []
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {
      provide: MatPaginatorIntl,
      useValue: germanPaginator
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
