import {Injectable} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {IEWarningComponent} from './ie-warning/i-e-warning.component';

@Injectable()
export class IEService {
  constructor(private readonly bottomSheet: MatBottomSheet) {}

  checkIfIE(): void {
    if (this.isIE()) {
      this.bottomSheet.open(IEWarningComponent);
    }
  }

  isIE(): boolean {
    return (window.navigator.userAgent && window.navigator.userAgent.indexOf('MSIE') > 0)
      || !!navigator.userAgent?.match(/Trident\/7\./);
  }
}
