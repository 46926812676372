import {ModuleWithProviders, NgModule} from '@angular/core';
import {SpinnerComponent} from './spinner/spinner.component';
import {UserService} from './user.service';
import {UserSessionService} from './user-session.service';
import {SecuredImageComponent} from './secured-image.component';
import {ClickStopPropagationDirective} from './click-stop-propagation.directive';
import {SpinnerService} from './spinner/spinner.service';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {ConfirmDialogService} from './confirm-dialog/confirm-dialog.service';
import {CommonModule} from '@angular/common';
import {MaterialDesignModule} from '../material-design.module';
import {TicketListItemComponent} from './tickets-list-item/ticket-list-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {KeydownStopPropagationDirective} from './keydown-stop-propagation.directive';
import {SortPipe} from './sort.pipe';
import {GroupByPipe} from './group-by.pipe';
import {MaxLengthPipe} from './max-length.pipe';
import {TicketCustomersDialogComponent} from './ticket-customers-dialog/ticket-customers-dialog.component';
import {SearchHighlightComponent} from './search-highlight/search-highlight.component';
import {RelativeTimePipe} from './relative-time.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import {CalendarHeaderComponent} from './calendar-header/calendar-header.component';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {ChangelogDialogComponent} from './changelog-dialog/changelog-dialog.component';
import {DndDirective} from './dnd.directive';
import {EscClearDirective} from './esc-clear.directive';
import {IEService} from '../main/ie.service';
import {FileUploadDialogComponent} from './file-upload-dialog/file-upload-dialog.component';
import {HostingHintComponent} from './hosting-hint/hosting-hint.component';
import {PhonePipe} from './pipes/phone.pipe';
import {UnsupportedBrowserWarningComponent} from './unsupported-browser-warning/unsupported-browser-warning.component';
import {PricePipe} from './pipes/price.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialDesignModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    SpinnerComponent,
    SecuredImageComponent,
    ClickStopPropagationDirective,
    KeydownStopPropagationDirective,
    ConfirmDialogComponent,
    TicketListItemComponent,
    ErrorDialogComponent,
    FooterComponent,
    SortPipe,
    GroupByPipe,
    MaxLengthPipe,
    SearchHighlightComponent,
    RelativeTimePipe,
    SafeHtmlPipe,
    DatepickerComponent,
    DndDirective,
    EscClearDirective,
    FileUploadDialogComponent,
    PricePipe,
    HostingHintComponent,
    PhonePipe,
    UnsupportedBrowserWarningComponent
  ],
  declarations: [
    SpinnerComponent,
    SecuredImageComponent,
    ClickStopPropagationDirective,
    KeydownStopPropagationDirective,
    ConfirmDialogComponent,
    TicketListItemComponent,
    ErrorDialogComponent,
    FooterComponent,
    SortPipe,
    GroupByPipe,
    MaxLengthPipe,
    TicketCustomersDialogComponent,
    SearchHighlightComponent,
    RelativeTimePipe,
    SafeHtmlPipe,
    DatepickerComponent,
    CalendarHeaderComponent,
    ChangelogDialogComponent,
    DndDirective,
    EscClearDirective,
    FileUploadDialogComponent,
    PricePipe,
    HostingHintComponent,
    PhonePipe,
    UnsupportedBrowserWarningComponent,
  ],
  providers: [
    IEService
  ]
})
export class AppCommonModule {
  static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        UserService,
        UserSessionService,
        SpinnerService,
        ConfirmDialogService
      ]
    };
  }
}
