<section class="navbar-wrapper">
  <nav class="navbar navbar-inverse mat-elevation-z6">
    <div class="container">
      <div class="navbar-header">
        <a routerLink="/" class="navbar-brand logo" mat-button>
          <img src="/assets/logo-bright.svg" alt="AP-Software" width="40">
        </a>
      </div>
    </div>
  </nav>
</section>

<div class="container">
  <div class="page-content">
    <img src="/assets/logo.svg" alt="AP-Software" class="logo">

    <h2>Die gesuchte Seite konnte nicht gefunden werden.</h2>
  </div>
</div>

<app-footer></app-footer>
