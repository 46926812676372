import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ApplicationStatusControllerService } from './api/applicationStatusController.service';
import { BankAccountControllerService } from './api/bankAccountController.service';
import { BookingControllerService } from './api/bookingController.service';
import { CategoryControllerService } from './api/categoryController.service';
import { CommentControllerService } from './api/commentController.service';
import { CompanyControllerService } from './api/companyController.service';
import { CustomerControllerService } from './api/customerController.service';
import { DiscountGroupControllerService } from './api/discountGroupController.service';
import { DocumentControllerService } from './api/documentController.service';
import { DocumentGroupControllerService } from './api/documentGroupController.service';
import { ExceptionTestControllerService } from './api/exceptionTestController.service';
import { InvitationControllerService } from './api/invitationController.service';
import { InvoiceControllerService } from './api/invoiceController.service';
import { MessageControllerService } from './api/messageController.service';
import { PriorityControllerService } from './api/priorityController.service';
import { SessionControllerService } from './api/sessionController.service';
import { StatusControllerService } from './api/statusController.service';
import { SummaryControllerService } from './api/summaryController.service';
import { TeamControllerService } from './api/teamController.service';
import { TemplateControllerService } from './api/templateController.service';
import { TicketControllerService } from './api/ticketController.service';
import { UploadControllerService } from './api/uploadController.service';
import { UserBalanceControllerService } from './api/userBalanceController.service';
import { UserControllerService } from './api/userController.service';
import { UserPackageControllerService } from './api/userPackageController.service';
import { UserPreferenceControllerService } from './api/userPreferenceController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApplicationStatusControllerService,
    BankAccountControllerService,
    BookingControllerService,
    CategoryControllerService,
    CommentControllerService,
    CompanyControllerService,
    CustomerControllerService,
    DiscountGroupControllerService,
    DocumentControllerService,
    DocumentGroupControllerService,
    ExceptionTestControllerService,
    InvitationControllerService,
    InvoiceControllerService,
    MessageControllerService,
    PriorityControllerService,
    SessionControllerService,
    StatusControllerService,
    SummaryControllerService,
    TeamControllerService,
    TemplateControllerService,
    TicketControllerService,
    UploadControllerService,
    UserBalanceControllerService,
    UserControllerService,
    UserPackageControllerService,
    UserPreferenceControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
