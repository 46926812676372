/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BankAccount } from './bankAccount';
import { Team } from './team';

export interface Customer { 
    bankAccounts?: Array<BankAccount>;
    created?: Date;
    customIdentifier?: string;
    dayOfBirth?: Date;
    displayName?: string;
    eTag?: string;
    firstName?: string;
    id?: number;
    lastName?: string;
    mobilePhone?: string;
    notes?: string;
    privateAddressAddition?: string;
    privateAddressCountry?: string;
    privateAddressStreetAndNumber?: string;
    privateAddressTownAndPostalCode?: string;
    privateMail?: string;
    privatePhone?: string;
    team?: Team;
    type?: Customer.TypeEnum;
    updated?: Date;
    version?: number;
    workAddressAddition?: string;
    workAddressCountry?: string;
    workAddressStreetAndNumber?: string;
    workAddressTownAndPostalCode?: string;
    workMail?: string;
    workPhone?: string;
}
export namespace Customer {
    export type TypeEnum = 'OTHER' | 'FEMALE' | 'MALE' | 'COMPANY';
    export const TypeEnum = {
        OTHER: 'OTHER' as TypeEnum,
        FEMALE: 'FEMALE' as TypeEnum,
        MALE: 'MALE' as TypeEnum,
        COMPANY: 'COMPANY' as TypeEnum
    };
}