import {ChangeDetectionStrategy, Component} from '@angular/core';
import * as versions from '../../../../release-notes.json';

@Component({
  selector: 'app-changelog-dialog',
  templateUrl: './changelog-dialog.component.html',
  styleUrls: ['./changelog-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangelogDialogComponent {

  readonly versions: Version[] = Object.keys(versions['default']).map((version: string) => {
    const notes = versions['default'][version];
    return {version, ...notes};
  });

}

interface Version {
  version: string;
  features: string[];
  bugfixes: string[];
}
