import {MatDialogRef} from '@angular/material/dialog';
import {ChangeDetectionStrategy, Component, NgZone} from '@angular/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent {

  constructor(private dialogRef: MatDialogRef<ErrorDialogComponent>,
              private ngZone: NgZone) {
  }

  public close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

}
