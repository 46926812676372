import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from './notification.service';
import {NGXLogger} from 'ngx-logger';
import {MatDialog} from '@angular/material/dialog';
import {BookingDialogType} from '../main/booking/dialogs/booking-dialog/booking-dialog-type.enum';
import {BookingDialogComponent} from '../main/booking/dialogs/booking-dialog/booking-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private notificationService: NotificationService,
              private translate: TranslateService,
              private logger: NGXLogger,
              private dialog: MatDialog) {
  }

  handleBackendErrorResponse(errorResponse: HttpErrorResponse): boolean {
    this.logger.debug('Handling generic backend error response:', errorResponse);

    const msgCode = errorResponse.error && errorResponse.error.msgCode;
    const hasMsgCode = !!msgCode;

    if (hasMsgCode) {
      if (msgCode.includes('create.limit.reached')) {
        const type = msgCode.includes('ticket') ? BookingDialogType.Tickets : BookingDialogType.Uploads;
        this.handleInsufficientPackage(type);
        return true;
      } else {
        const translation = this.translate.instant(msgCode);
        const isUnknownError = translation === msgCode;
        this.notificationService.displayNotification(isUnknownError ? this.translate.instant('error.unknown') : translation, 5000, true);
      }
    } else if (errorResponse.status === 401) {
      this.notificationService.displayNotification(this.translate.instant('error.unauthenticated'), 5000, true);
      return true;
    } else if (errorResponse.status === 403) {
      this.notificationService.displayNotification(this.translate.instant('error.forbidden'), 5000, true);
      return true;
    } else if (errorResponse.status === 412) {
      this.notificationService.displayNotification(this.translate.instant('error.conflict'), 5000, true);
      return true;
    }

    return hasMsgCode;
  }

  private handleInsufficientPackage(type: BookingDialogType): void {
    this.dialog.open(BookingDialogComponent, {
      data: { type },
      width: '500px'
    });
  }

}
