/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BookedUserPackage } from '../model/bookedUserPackage';
import { BookingRequest } from '../model/bookingRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BookingControllerService {

    protected basePath = '//localhost/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Change pricing for the given booked package.
     * 
     * @param bookedUserPackageId bookedUserPackageId
     * @param priceCents priceCents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePackagePricing(bookedUserPackageId: number, priceCents: number, observe?: 'body', reportProgress?: boolean): Observable<BookedUserPackage>;
    public changePackagePricing(bookedUserPackageId: number, priceCents: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookedUserPackage>>;
    public changePackagePricing(bookedUserPackageId: number, priceCents: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookedUserPackage>>;
    public changePackagePricing(bookedUserPackageId: number, priceCents: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookedUserPackageId === null || bookedUserPackageId === undefined) {
            throw new Error('Required parameter bookedUserPackageId was null or undefined when calling changePackagePricing.');
        }

        if (priceCents === null || priceCents === undefined) {
            throw new Error('Required parameter priceCents was null or undefined when calling changePackagePricing.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookedUserPackageId !== undefined && bookedUserPackageId !== null) {
            queryParameters = queryParameters.set('bookedUserPackageId', <any>bookedUserPackageId);
        }
        if (priceCents !== undefined && priceCents !== null) {
            queryParameters = queryParameters.set('priceCents', <any>priceCents);
        }

        let headers = this.defaultHeaders;

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BookedUserPackage>('post',`${this.basePath}/invoice/booking/pricing`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new booking based on the given data.
     * 
     * @param body data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBookedUserPackage(body: BookingRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<BookedUserPackage>>;
    public createBookedUserPackage(body: BookingRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookedUserPackage>>>;
    public createBookedUserPackage(body: BookingRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookedUserPackage>>>;
    public createBookedUserPackage(body: BookingRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createBookedUserPackage.');
        }

        let headers = this.defaultHeaders;

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<BookedUserPackage>>('post',`${this.basePath}/invoice/booking/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all booked packages for the given user.
     * 
     * @param userId userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookedUserPackage(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BookedUserPackage>>;
    public getBookedUserPackage(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookedUserPackage>>>;
    public getBookedUserPackage(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookedUserPackage>>>;
    public getBookedUserPackage(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getBookedUserPackage.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BookedUserPackage>>('get',`${this.basePath}/invoice/booking/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rebook booked packages without an end date.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rebookPackages(observe?: 'body', reportProgress?: boolean): Observable<Array<BookedUserPackage>>;
    public rebookPackages(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookedUserPackage>>>;
    public rebookPackages(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookedUserPackage>>>;
    public rebookPackages(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BookedUserPackage>>('post',`${this.basePath}/invoice/booking/rebook`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets an end date for a given booked package.
     * 
     * @param bookedUserPackageId bookedUserPackageId
     * @param endDate endDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setEndDate(bookedUserPackageId: number, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<BookedUserPackage>;
    public setEndDate(bookedUserPackageId: number, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookedUserPackage>>;
    public setEndDate(bookedUserPackageId: number, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookedUserPackage>>;
    public setEndDate(bookedUserPackageId: number, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookedUserPackageId === null || bookedUserPackageId === undefined) {
            throw new Error('Required parameter bookedUserPackageId was null or undefined when calling setEndDate.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookedUserPackageId !== undefined && bookedUserPackageId !== null) {
            queryParameters = queryParameters.set('bookedUserPackageId', <any>bookedUserPackageId);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BookedUserPackage>('post',`${this.basePath}/invoice/booking/endDate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
