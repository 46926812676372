import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SpinnerService} from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  public isVisible = false;

  constructor(public spinnerService: SpinnerService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.spinnerService.setSpinnerContainerRef(this);
  }

  ngOnDestroy() {
    this.spinnerService.unsetSpinnerContainerRef(this);
  }

  public showSpinner() {
    this.isVisible = true;
    this.cdr.detectChanges();
  }

  public hideSpinner() {
    this.isVisible = false;
    this.cdr.detectChanges();
  }
}
