import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {CustomerControllerService, CustomerSummary} from '@api';
import {CustomerApi} from '../actions/customer-api.actions';
import {catchError, tap} from 'rxjs/operators';
import {CustomersOverview} from '../actions/customers-overview.actions';
import {EMPTY} from 'rxjs';
import {NotificationService} from '@common/notification.service';

export interface CustomerOverviewStateModel {
  isLoading: boolean;
  areCustomersRetrieved: boolean;
  retrievedTeamId: number;
  customers: CustomerSummary[];
}

@State<CustomerOverviewStateModel>({
  name: 'customerOverview',
  defaults: {
    isLoading: false,
    areCustomersRetrieved: false,
    retrievedTeamId: null,
    customers: []
  }
})
@Injectable()
export class CustomerOverviewState {
  @Selector()
  static customers(state: CustomerOverviewStateModel) {
    return state.customers;
  }

  @Selector()
  static isLoading(state: CustomerOverviewStateModel) {
    return state.isLoading;
  }

  @Selector()
  static areCustomersRetrieved(state: CustomerOverviewStateModel) {
    return state.areCustomersRetrieved;
  }

  @Selector()
  static retrievedTeamId(state: CustomerOverviewStateModel) {
    return state.retrievedTeamId;
  }

  constructor(private customerApiService: CustomerControllerService,
              private notificationService: NotificationService) {
  }

  @Action(CustomersOverview.FetchCustomersOfTeam)
  fetchCustomers(ctx: StateContext<CustomerOverviewStateModel>, action: CustomersOverview.FetchCustomersOfTeam) {
    ctx.setState(state => ({
      ...state,
      isLoading: true
    }));

    return this.customerApiService.getCustomersOverview(action.teamId).pipe(
      tap((customers: CustomerSummary[]) => {
        ctx.setState(state => ({
          ...state,
          isLoading: false,
          areCustomersRetrieved: true,
          retrievedTeamId: action.teamId,
          customers
        }));
      }),
      catchError(() => {
        ctx.setState(state => ({
          ...state,
          isLoading: false,
          areCustomersRetrieved: false,
          retrievedTeamId: null
        }));
        this.notificationService.displayNotification('Kunden konnten nicht geladen werden', 5000, true);
        return EMPTY;
      })
    );
  }

  @Action(CustomerApi.CreateSuccess)
  customerCreated(ctx: StateContext<CustomerOverviewStateModel>, action: CustomerApi.CreateSuccess) {
    if (action.createdEntity.team.id === ctx.getState().retrievedTeamId) {
      const summary: CustomerSummary = {
        customIdentifier: action.createdEntity.customIdentifier,
        displayName: action.createdEntity.displayName,
        firstName: action.createdEntity.firstName,
        id: action.createdEntity.id,
        lastName: action.createdEntity.lastName,
        teamId: action.createdEntity.team.id,
        ticketCount: {
          completed: 0,
          open: 0,
          waiting: 0
        }
      };

      ctx.setState(state => ({
        ...state,
        customers: [...state.customers, summary]
      }));
    }
  }

  @Action(CustomerApi.DeleteSuccess)
  customerDeleted(ctx: StateContext<CustomerOverviewStateModel>, action: CustomerApi.DeleteSuccess) {
    ctx.setState(state => ({
      ...state,
      customers: state.customers.filter(customer => customer.id !== action.deletedEntityId)
    }));
  }

  @Action(CustomerApi.CustomerNotFound)
  customerNotFound(ctx: StateContext<CustomerOverviewStateModel>, action: CustomerApi.CustomerNotFound) {
    ctx.setState(state => ({
      ...state,
      customers: state.customers.filter(customer => customer.id !== action.customerId)
    }));
  }

  @Action(CustomersOverview.TicketForCustomerCreated)
  ticketForCustomerCreated(ctx: StateContext<CustomerOverviewStateModel>, action: CustomersOverview.TicketForCustomerCreated) {
    ctx.setState(state => {
      return {
        ...state,
        customers: state.customers.map((customer: CustomerSummary) => {
          if (customer.id === action.customerId) {
            return {
              ...customer,
              ticketCount: {
                ...customer.ticketCount,
                open: customer.ticketCount ? customer.ticketCount.open + 1 : 1
              }
            };
          }
          return customer;
        })
      };
    });
  }

  @Action(CustomerApi.UpdateSuccess)
  saveCustomer(ctx: StateContext<CustomerOverviewStateModel>, action: CustomerApi.UpdateSuccess) {
    ctx.setState(state => ({
      ...state,
      customers: state.customers.map(customer => {
        if (customer.id === action.updatedEntity.id) {
          return action.updatedEntity;
        }
        return customer;
      })
    }));
  }
}
