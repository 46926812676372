import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {UserSessionService} from './user-session.service';
import {UserService} from './user.service';
import {catchError} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userSessionService: UserSessionService,
              private userService: UserService,
              private router: Router,
              private readonly logger: NGXLogger,
              private readonly ngZone: NgZone,
              @Inject('origin') public origin: string,
              @Inject('domain') public domain: string) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only intercept auth for requests to our backend and not external sources
    if (!request.url.includes(this.domain)) {
      return next.handle(request);
    }

    // Add the headers to allow authentication
    if (this.userSessionService.session) {
      request = request.clone({
        headers: request.headers
          .set('X-Session', this.userSessionService.session.apiKey)
      });
    }

    // Handle 401 errors by redirecting to the login page
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (
            err.status === 401 &&
            !request.url.endsWith('/common/users/actions/setPassword') &&
            !request.url.endsWith('/session') &&
            !request.url.includes('/upload/uploads/public/')
          ) {
            this.userService.removeSessionFromLocalStorage();
            this.userService.sessionInformation.next(null);

            const referer = this.getReferer();
            this.ngZone.run(() => {
              if (referer) {
                return this.router.navigate(['/login'], {
                  queryParams: { redirect: referer }
                });
              } else {
                return this.router.navigate(['/login']);
              }
            });

            return EMPTY;
          }
        }
        return throwError(err);
      })
    );
  }

  private getReferer() {
    const pathname = window?.location?.pathname;
    if (pathname && pathname !== '/login') {
      return pathname;
    }
    return undefined;
  }
}
