import {Subscription} from 'rxjs';

export class Spinner {
  private timeoutSub: Subscription;

  setTimeoutSub(sub: Subscription): void {
    this.timeoutSub = sub;
  }

  finish(): void {
    this.timeoutSub.unsubscribe();
  }
}
