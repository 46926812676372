import {Component, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TourService as Tour} from 'ngx-tour-md-menu';
import {TourService} from '@common/tour.service';
import {TitleService} from './title.service';
import {SpinnerService} from '@common/spinner/spinner.service';
import {Spinner} from '@common/spinner/spinner';
import {SlackService} from './slack.service';
import {NGXLogger} from 'ngx-logger';
import {GlobalLogListener} from './global-log-listener';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private translateService: TranslateService,
              private router: Router,
              private tour: Tour,
              private tourService: TourService,
              private renderer: Renderer2,
              private titleService: TitleService,
              private slack: SlackService,
              private spinnerService: SpinnerService,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.logger.registerMonitor(new GlobalLogListener());
    this.logger.debug('Frontend started');

    this.translateService.setDefaultLang('de');
    this.translateService.use('de');

    let pageLoadSpinner: Spinner;

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.logger.debug(`Navigation started to ${event.url}`);

      if (pageLoadSpinner) {
        this.spinnerService.hideSpinner(pageLoadSpinner);
      }
      pageLoadSpinner = this.spinnerService.showSpinner('page.load');
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logger.debug(`Navigation to ${event.url} ended, after redirects: ${event.urlAfterRedirects}`);

      window.scrollTo(0, 0);

      this.spinnerService.hideSpinner(pageLoadSpinner);
      pageLoadSpinner = undefined;

      this.tourService.disableTour();
      this.titleService.resetTitle();
    });

    this.setupTour();
  }

  private setupTour(): void {
    this.tour.initialize([
      {
        stepId: 'dashboard',
        anchorId: 'dashboard.overall',
        title: 'Dashboard',
        content: 'Willkommen bei AP-Software! Sie befinden sich auf dem Dashboard. Hier finden Sie alle wichtigen Übersichten ' +
          'auf einen Blick.',
        endBtnTitle: 'Beenden',
        route: '/dashboard'
      },
      {
        anchorId: 'dashboard.tasks',
        title: 'Aufgabenübersicht',
        content: 'Hier werden alle anstehenden und überfälligen Aufgaben Ihres Teams gezeigt. ' +
          'Die Übersicht enthält ebenfalls die Tage, bis die Aufgabe fällig bzw. seit wann sie überfällig ist.',
        endBtnTitle: 'Beenden',
        route: '/dashboard',
        enableBackdrop: true
      },
      {
        anchorId: 'dashboard.uploads',
        title: 'Aktive Uploads',
        content: 'Hier werden alle Dokumentenuploads dargestellt, die noch nicht abgeschlossenen sind. ' +
          'Die Übersicht enthält den Zeitpunkt der letzten Änderung sowie die Anzahl neuer Uploads durch Kunden.',
        endBtnTitle: 'Beenden',
        route: '/dashboard',
        enableBackdrop: true
      },
      {
        anchorId: 'dashboard.activity',
        title: 'Kundenaktivität',
        content: 'Zeigt den zuletzt von einem Teammitglied bearbeiteten Kunden an.',
        endBtnTitle: 'Beenden',
        route: '/dashboard',
        enableBackdrop: true
      },
      {
        anchorId: 'dashboard.team',
        title: 'Teamübersicht',
        content: 'Diese Übersicht zeigt die Mitglieder des aktiven Teams sowie die Anzahl ihrer offenen Tickets.',
        endBtnTitle: 'Beenden',
        route: '/dashboard',
        enableBackdrop: true
      },
      {
        anchorId: 'header.logo',
        title: 'Dashboard',
        content: 'Falls Sie sich auf einer anderen Seite befinden, können Sie mit einem Klick auf das Logo zurück zum Dashboard gelangen.',
        endBtnTitle: 'Beenden',
        route: '/dashboard',
        enableBackdrop: true
      },
      {
        anchorId: 'header.team',
        title: 'Aktuelles Team',
        content: 'Über dieses Menü können Sie Ihr aktuelles Team auswählen. Dadurch werden die Übersichten auf der gesamten Seite ' +
          'angepasst.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true
      },
      {
        stepId: 'uploads',
        anchorId: 'uploads.overall',
        title: 'Upload',
        content: 'Auf dieser Seite finden Sie eine Übersicht aller Uploads des aktuellen Teams.',
        endBtnTitle: 'Beenden',
        route: '/uploads'
      },
      {
        stepId: 'uploads',
        anchorId: 'uploads.templates',
        title: 'Vorlagen',
        content: 'Wechseln Sie den Tab, um eine Übersicht der Vorlagen zu sehen, die Ihr Team erstellt hat oder die mit Ihrem Team ' +
          'geteilt wurden.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true,
        route: '/uploads'
      },
      {
        anchorId: 'uploads.wizard',
        title: 'Upload erstellen',
        content: 'Um einen neuen Upload zu erstellen, klicken Sie diesen Button, um zum Wizard zu gelangen.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true,
        route: '/uploads'
      },
      {
        stepId: 'wizard',
        anchorId: 'wizard.overall',
        title: 'Wizard',
        content: 'Der Wizard hilft beim Erstellen eines Uploads für Ihre Kunden.',
        endBtnTitle: 'Beenden',
        route: '/wizard/upload'
      },
      {
        stepId: 'wizard',
        anchorId: 'wizard.help',
        title: 'Wizard',
        content: 'Beachten Sie die Hilfestellungen und Erklärungen in jedem Schritt.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true,
        route: '/wizard/upload'
      },
      {
        stepId: 'wizard',
        anchorId: 'wizard.continue',
        title: 'Wizard',
        content: 'Verwenden Sie den Weiter-Button, um zum nächsten Schritt zu gelangen. Beenden Sie die Tour, ' +
          'um den Wizard auszuprobieren.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true,
        route: '/wizard/upload'
      },
      {
        stepId: 'ticket',
        anchorId: 'ticket.overall',
        title: 'Ticketansicht',
        content: 'Dies ist die Ticketansicht, auf der sie weitere Details finden. Ebenfalls können Sie hier alle Einstellungen des ' +
          'Tickets anpassen, sofern Sie Mitglied des Teams sind.',
        endBtnTitle: 'Beenden',
        route: '/tickets/ticket/example'
      },
      {
        stepId: 'ticket',
        anchorId: 'ticket.body',
        title: 'Ticketbeschreibung',
        content: 'Nutzen Sie die Ticketbeschreibung, um weitere Informationen und Arbeitsschritte zu diesem Ticket zu formulieren. Sie ' +
          'können ebenfalls Dateien anhängen.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true
      },
      {
        stepId: 'ticket',
        anchorId: 'ticket.activity',
        title: 'Aktivität',
        content: 'Die Aktivitätsübersicht zeigt alle Änderungen und Kommentare für dieses Ticket in chronologischer Reihenfolge.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true
      },
      {
        stepId: 'ticket',
        anchorId: 'ticket.attributes',
        title: 'Ticketeigenschaften',
        content: 'Hier sehen Sie alle wichtigen Eigenschaften des Tickets zusammengefasst. Um Ihnen maximale Effizienz zu gewährleisten, ' +
          'können Sie diese Eigenschaften mit einem Klick anpassen.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true
      },
      {
        stepId: 'ticket',
        anchorId: 'ticket.customers',
        title: 'Kunden',
        content: 'Wählen Sie hier alle Kunden aus, die dieses Ticket betrifft, um den Überblick über alle Informationen zu behalten.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true
      },
      {
        stepId: 'ticket',
        anchorId: 'ticket.watchers',
        title: 'Beobachter',
        content: 'Hier können Sie Beobachter aus anderen Teams für dieses Ticket einstellen. Beobachter haben Zugriff auf alle ' +
          'Informationen in dem Ticket, können jedoch keine Änderungen daran vornehmen.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true
      },
      {
        stepId: 'ticket',
        anchorId: 'ticket.edit',
        title: 'Ticket bearbeiten',
        content: 'Verwenden Sie diese Buttons, um den Titel und die Beschreibung Ihres Tickets zu bearbeiten oder das Ticket zu löschen.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true,
        route: '/tickets/ticket/example'
      },
      {
        stepId: 'customers',
        anchorId: 'customers.overall',
        title: 'Kunden',
        content: 'Hier finden Sie eine Übersicht aller Kunden des aktuellen Teams.',
        endBtnTitle: 'Beenden',
        route: '/customers'
      },
      {
        anchorId: 'customers.create',
        title: 'Neuen Kunden anlegen',
        content: 'Um einen neuen Kunden zu erstellen, klicken Sie auf diesen Button.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true,
        route: '/customers'
      },
      {
        anchorId: 'customers.import',
        title: 'Kunden importieren',
        content: 'Alternativ können Sie auch mehrere Kunden gleichzeitig aus einer Datei importieren.',
        endBtnTitle: 'Beenden',
        enableBackdrop: true,
        route: '/customers'
      },
    ]);

    this.tour.start$.subscribe(() => {
      this.logger.info('Tour started');
      this.renderer.addClass(document.body, 'tour-active');
    });

    this.tour.end$.subscribe(() => {
      this.logger.info('Tour ended');
      this.renderer.removeClass(document.body, 'tour-active');
    });
  }

}
