import {Injectable} from '@angular/core';
import {ConfirmDialogComponent} from './confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Injectable()
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  public show(title: string = '', message: string = '', confirmText: string = 'Bestätigen', cancelText: string = 'Abbrechen'): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title,
        message,
        confirmText,
        cancelText
      }
    });

    return dialogRef.afterClosed();
  }
}
