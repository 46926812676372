<div class="package">
  <div class="package__content">
    <h4>{{pkg.displayName}}</h4>

    <div *ngIf="pkg.constraints.maxTotalUploads !== 0">
      Erhöhung maximale Uploads auf
      <strong>{{pkg.constraints.maxTotalUploads < 0 ? 'unbegrenzt' : (pkg.constraints.maxTotalUploads | number)}}</strong>
    </div>

    <div *ngIf="pkg.constraints.maxTotalTickets !== 0">
      Erhöhung maximale Tickets auf
      <strong>{{pkg.constraints.maxTotalTickets < 0 ? 'unbegrenzt' : (pkg.constraints.maxTotalTickets | number)}}</strong>
    </div>

    <div *ngIf="pkg.constraints.maxMbStorage !== 0">
      Erhöhung Cloudspeicher auf
      <strong>{{pkg.constraints.maxMbStorage < 0 ? 'unbegrenzt' : (pkg.constraints.maxMbStorage | number) + ' MB'}}</strong>
    </div>

    <span class="package__pricing">
      {{pkg.actualPriceCents | price}}
      <ng-container *ngIf="pkg.billingType === 'MONTHLY'">/ Monat</ng-container>
      <ng-container *ngIf="pkg.billingType === 'ONCE'">einmalig</ng-container>
    </span>
  </div>

  <button mat-icon-button color="primary" matTooltip="Buchen" (click)="book()" *ngIf="userId">
    <mat-icon>book_online</mat-icon>
  </button>
  <button
    *ngIf="showAdminActions && pkg.groupName !== 'package_group.default' && pkg.groupName !== 'package_group.starter'"
    mat-icon-button
    color="primary"
    matTooltip="Paket bearbeiten"
    (click)="edit.emit()"
  >
    <mat-icon>edit</mat-icon>
  </button>
</div>
