import {Injectable} from '@angular/core';
import {SpinnerComponent} from './spinner.component';
import {timer} from 'rxjs';
import {Spinner} from './spinner';
import {NotificationService} from '../notification.service';
import {GlobalErrorHandler} from '../../global-error-handler';

@Injectable()
export class SpinnerService {

  private spinnerContainerRef: SpinnerComponent | null = null;
  private multiSpinner = 0;

  private readonly reportTimeoutsInSlack = false;

  constructor(private notificationService: NotificationService,
              private globalErrorHandler: GlobalErrorHandler) {
  }

  public showSpinner(operationId: string, timeout: number = 8000): Spinner {
    this.multiSpinner++;
    this.checkSpinner();

    const spinner = new Spinner();

    const timeoutSub = timer(timeout).subscribe(() => {
      this.hideSpinner(spinner);

      const message = 'Die Anfrage dauert zu lang. Bitte überprüfen Sie Ihre Internetverbindung.';
      this.notificationService.displayNotification(message, 5000, true);

      if (this.reportTimeoutsInSlack) {
        const error = new Error(`Spinner timeout reached for operation '${operationId}'`);
        this.globalErrorHandler.sendErrorInSlack(error);
      }
    });
    spinner.setTimeoutSub(timeoutSub);

    return spinner;
  }

  public hideSpinner(spinner: Spinner): void {
    this.multiSpinner--;
    this.checkSpinner();

    spinner.finish();
  }

  public setSpinnerContainerRef(ref: SpinnerComponent) {
    this.spinnerContainerRef = ref;
    this.checkSpinner();
  }

  public unsetSpinnerContainerRef(ref: SpinnerComponent) {
    if (this.spinnerContainerRef === ref) {
      this.spinnerContainerRef = null;
    }
  }

  private checkSpinner(): void {
    if (this.spinnerContainerRef !== null) {
      if (this.multiSpinner >= 1) {
        this.spinnerContainerRef.showSpinner();
      } else {
        this.spinnerContainerRef.hideSpinner();
      }
    }
  }
}
