/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { ExceptionResponse } from './exceptionResponse';

export interface CustomerImportEntry { 
    changedFields?: Array<string>;
    customer?: Customer;
    decision?: CustomerImportEntry.DecisionEnum;
    duplicates?: Array<Customer>;
    error?: ExceptionResponse;
}
export namespace CustomerImportEntry {
    export type DecisionEnum = 'ALREADY_KNOWN' | 'CREATE_NEW' | 'ID_MATCH_NO_CONFLICT' | 'ID_MATCH_NAME_CONFLICT' | 'ONLY_NAME_MATCH' | 'EXACT_NAME_MATCH' | 'DECISION_NEEDED' | 'EXCEPTION';
    export const DecisionEnum = {
        ALREADYKNOWN: 'ALREADY_KNOWN' as DecisionEnum,
        CREATENEW: 'CREATE_NEW' as DecisionEnum,
        IDMATCHNOCONFLICT: 'ID_MATCH_NO_CONFLICT' as DecisionEnum,
        IDMATCHNAMECONFLICT: 'ID_MATCH_NAME_CONFLICT' as DecisionEnum,
        ONLYNAMEMATCH: 'ONLY_NAME_MATCH' as DecisionEnum,
        EXACTNAMEMATCH: 'EXACT_NAME_MATCH' as DecisionEnum,
        DECISIONNEEDED: 'DECISION_NEEDED' as DecisionEnum,
        EXCEPTION: 'EXCEPTION' as DecisionEnum
    };
}