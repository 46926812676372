/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FieldModification } from '../model/fieldModification';
import { Template } from '../model/template';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TemplateControllerService {

    protected basePath = '//localhost/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createTemplateFromTemplate
     * 
     * @param newName newName
     * @param newTeam newTeam
     * @param templateId templateId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTemplateFromTemplate(newName: string, newTeam: number, templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public createTemplateFromTemplate(newName: string, newTeam: number, templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public createTemplateFromTemplate(newName: string, newTeam: number, templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public createTemplateFromTemplate(newName: string, newTeam: number, templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (newName === null || newName === undefined) {
            throw new Error('Required parameter newName was null or undefined when calling createTemplateFromTemplate.');
        }

        if (newTeam === null || newTeam === undefined) {
            throw new Error('Required parameter newTeam was null or undefined when calling createTemplateFromTemplate.');
        }

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling createTemplateFromTemplate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newName !== undefined && newName !== null) {
            queryParameters = queryParameters.set('newName', <any>newName);
        }
        if (newTeam !== undefined && newTeam !== null) {
            queryParameters = queryParameters.set('newTeam', <any>newTeam);
        }
        if (templateId !== undefined && templateId !== null) {
            queryParameters = queryParameters.set('templateId', <any>templateId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Template>('post',`${this.basePath}/upload/templates/from/template`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTemplateFromUpload
     * 
     * @param newName newName
     * @param newTeam newTeam
     * @param uploadId uploadId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTemplateFromUpload(newName: string, newTeam: number, uploadId: number, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public createTemplateFromUpload(newName: string, newTeam: number, uploadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public createTemplateFromUpload(newName: string, newTeam: number, uploadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public createTemplateFromUpload(newName: string, newTeam: number, uploadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (newName === null || newName === undefined) {
            throw new Error('Required parameter newName was null or undefined when calling createTemplateFromUpload.');
        }

        if (newTeam === null || newTeam === undefined) {
            throw new Error('Required parameter newTeam was null or undefined when calling createTemplateFromUpload.');
        }

        if (uploadId === null || uploadId === undefined) {
            throw new Error('Required parameter uploadId was null or undefined when calling createTemplateFromUpload.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newName !== undefined && newName !== null) {
            queryParameters = queryParameters.set('newName', <any>newName);
        }
        if (newTeam !== undefined && newTeam !== null) {
            queryParameters = queryParameters.set('newTeam', <any>newTeam);
        }
        if (uploadId !== undefined && uploadId !== null) {
            queryParameters = queryParameters.set('uploadId', <any>uploadId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Template>('post',`${this.basePath}/upload/templates/from/upload`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new entity based on the given data.
     * 
     * @param body data
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUploadTemplate(body: Template, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public createUploadTemplate(body: Template, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public createUploadTemplate(body: Template, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public createUploadTemplate(body: Template, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createUploadTemplate.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Template>('post',`${this.basePath}/upload/templates/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUploadTemplate(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUploadTemplate(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUploadTemplate(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUploadTemplate(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUploadTemplate.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/upload/templates/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read all visible entities. If the user is not allowed to read any entity, an empty list will be returned.
     * 
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param limit limit
     * @param offset offset
     * @param team team
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUploadTemplates(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Template>>;
    public getAllUploadTemplates(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Template>>>;
    public getAllUploadTemplates(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Template>>>;
    public getAllUploadTemplates(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (team !== undefined && team !== null) {
            queryParameters = queryParameters.set('team', <any>team);
        }

        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Template>>('get',`${this.basePath}/upload/templates/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an entity based on the given id.
     * 
     * @param id id
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadTemplate(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public getUploadTemplate(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public getUploadTemplate(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public getUploadTemplate(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUploadTemplate.');
        }



        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Template>('get',`${this.basePath}/upload/templates/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given data.
     * 
     * @param body data
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overwriteUploadTemplate(body: Template, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public overwriteUploadTemplate(body: Template, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public overwriteUploadTemplate(body: Template, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public overwriteUploadTemplate(body: Template, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling overwriteUploadTemplate.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling overwriteUploadTemplate.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Template>('put',`${this.basePath}/upload/templates/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given modifications. The modified entity is returned.
     * 
     * @param body modifications
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUploadTemplate(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Template>;
    public patchUploadTemplate(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Template>>;
    public patchUploadTemplate(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Template>>;
    public patchUploadTemplate(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchUploadTemplate.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchUploadTemplate.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Template>('patch',`${this.basePath}/upload/templates/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
