import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SlackService {

  constructor(private http: HttpClient) {
  }

  sendSlackSupportMessage(message: string): void {
    this.http.post(`https://hooks.slack.com/services/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`, JSON.stringify({
      text: message
    }), {
      responseType: 'text'
    }).subscribe();
  }

}
