<div class="dialog-title">
  <h1 matDialogTitle>Maximale Anzahl an <ng-container [ngSwitch]="data.type">
    <span *ngSwitchCase="BookingDialogType.Uploads">Uploads</span>
    <span *ngSwitchCase="BookingDialogType.Tickets">Tickets</span>
  </ng-container> erreicht</h1>

  <button mat-icon-button matDialogClose>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="packages$ | async as packages">
  <div *ngIf="packages.length else noPackages">
    Um weitere <ng-container [ngSwitch]="data.type">
      <span *ngSwitchCase="BookingDialogType.Uploads">Uploads</span>
      <span *ngSwitchCase="BookingDialogType.Tickets">Tickets</span>
    </ng-container> zu erstellen, stehen folgende Pakete zur Verfügung.

    <app-bookable-package
      [pkg]="pkg"
      [userId]="userId$ | async"
      (booked)="dialogRef.close()"
      class="package"
      *ngFor="let pkg of packages$ | async"
    ></app-bookable-package>
  </div>

  <ng-template #noPackages>
    Aktuell stehen keine Pakete zur Verfügung, um die maximale Anzahl zu erhöhen.
  </ng-template>
</div>
