<h1 matDialogTitle>Release Notes</h1>

<div class="version" *ngFor="let version of versions">
  <h3 class="version__name">{{version.version}}</h3>

  <h4 class="version__subtitle" *ngIf="version.features?.length">Features</h4>
  <ul class="version__list">
    <li *ngFor="let feature of version.features">{{feature}}</li>
  </ul>

  <h4 class="version__subtitle" *ngIf="version.bugfixes?.length">Bugfixes</h4>
  <ul class="version__list">
    <li *ngFor="let bugfix of version.bugfixes">{{bugfix}}</li>
  </ul>
</div>
