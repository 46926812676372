import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserPackage} from '@api';
import {
  ConfirmBookPackageDialogComponent,
  ConfirmBookPackageDialogData
} from '../../dialogs/confirm-book-package-dialog/confirm-book-package-dialog.component';

@Component({
  selector: 'app-bookable-package',
  templateUrl: './bookable-package.component.html',
  styleUrls: ['./bookable-package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookablePackageComponent {
  @Input() pkg: UserPackage;
  @Input() userId: number;
  @Input() showAdminActions: boolean;

  @Output() booked: EventEmitter<void> = new EventEmitter();
  @Output() edit: EventEmitter<void> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  book(): void {
    const dialogRef = this.dialog.open(ConfirmBookPackageDialogComponent, {
      data: {
        pkg: this.pkg,
        userId: this.userId
      } as ConfirmBookPackageDialogData,
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.booked.emit();
    });
  }
}
