/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Comment } from '../model/comment';
import { DataObject } from '../model/dataObject';
import { FieldModification } from '../model/fieldModification';
import { Resource } from '../model/resource';
import { Upload } from '../model/upload';
import { UploadTemplateSummary } from '../model/uploadTemplateSummary';
import { UploadsOverview } from '../model/uploadsOverview';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UploadControllerService {

    protected basePath = '//localhost/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a comment from customer.
     * 
     * @param commentBody commentBody
     * @param customerId customerId
     * @param documentId documentId
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCustomerComment(commentBody: string, customerId: number, documentId: number, token: string, observe?: 'body', reportProgress?: boolean): Observable<Comment>;
    public addCustomerComment(commentBody: string, customerId: number, documentId: number, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Comment>>;
    public addCustomerComment(commentBody: string, customerId: number, documentId: number, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Comment>>;
    public addCustomerComment(commentBody: string, customerId: number, documentId: number, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commentBody === null || commentBody === undefined) {
            throw new Error('Required parameter commentBody was null or undefined when calling addCustomerComment.');
        }

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling addCustomerComment.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling addCustomerComment.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling addCustomerComment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (commentBody !== undefined && commentBody !== null) {
            queryParameters = queryParameters.set('commentBody', <any>commentBody);
        }
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Comment>('post',`${this.basePath}/upload/uploads/public/${encodeURIComponent(String(token))}/actions/addCustomerComment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a client uploaded file to the upload.
     * 
     * @param file 
     * @param documentId documentId
     * @param token token
     * @param filename filename
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCustomerUploadForm(file: Blob, documentId: number, token: string, filename?: string, observe?: 'body', reportProgress?: boolean): Observable<DataObject>;
    public addCustomerUploadForm(file: Blob, documentId: number, token: string, filename?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataObject>>;
    public addCustomerUploadForm(file: Blob, documentId: number, token: string, filename?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataObject>>;
    public addCustomerUploadForm(file: Blob, documentId: number, token: string, filename?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling addCustomerUpload.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling addCustomerUpload.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling addCustomerUpload.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }
        if (filename !== undefined && filename !== null) {
            queryParameters = queryParameters.set('filename', <any>filename);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<DataObject>('post',`${this.basePath}/upload/uploads/public/${encodeURIComponent(String(token))}/actions/addCustomerUpload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new entity based on the given data.
     * 
     * @param body data
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUpload(body: Upload, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Upload>;
    public createUpload(body: Upload, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Upload>>;
    public createUpload(body: Upload, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Upload>>;
    public createUpload(body: Upload, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createUpload.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Upload>('post',`${this.basePath}/upload/uploads/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createUploadFromTemplate
     * 
     * @param newName newName
     * @param newTeam newTeam
     * @param templateId templateId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUploadFromTemplate(newName: string, newTeam: number, templateId: number, observe?: 'body', reportProgress?: boolean): Observable<Upload>;
    public createUploadFromTemplate(newName: string, newTeam: number, templateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Upload>>;
    public createUploadFromTemplate(newName: string, newTeam: number, templateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Upload>>;
    public createUploadFromTemplate(newName: string, newTeam: number, templateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (newName === null || newName === undefined) {
            throw new Error('Required parameter newName was null or undefined when calling createUploadFromTemplate.');
        }

        if (newTeam === null || newTeam === undefined) {
            throw new Error('Required parameter newTeam was null or undefined when calling createUploadFromTemplate.');
        }

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling createUploadFromTemplate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newName !== undefined && newName !== null) {
            queryParameters = queryParameters.set('newName', <any>newName);
        }
        if (newTeam !== undefined && newTeam !== null) {
            queryParameters = queryParameters.set('newTeam', <any>newTeam);
        }
        if (templateId !== undefined && templateId !== null) {
            queryParameters = queryParameters.set('templateId', <any>templateId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Upload>('post',`${this.basePath}/upload/uploads/from/template`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createUploadFromUpload
     * 
     * @param newName newName
     * @param newTeam newTeam
     * @param uploadId uploadId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUploadFromUpload(newName: string, newTeam: number, uploadId: number, observe?: 'body', reportProgress?: boolean): Observable<Upload>;
    public createUploadFromUpload(newName: string, newTeam: number, uploadId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Upload>>;
    public createUploadFromUpload(newName: string, newTeam: number, uploadId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Upload>>;
    public createUploadFromUpload(newName: string, newTeam: number, uploadId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (newName === null || newName === undefined) {
            throw new Error('Required parameter newName was null or undefined when calling createUploadFromUpload.');
        }

        if (newTeam === null || newTeam === undefined) {
            throw new Error('Required parameter newTeam was null or undefined when calling createUploadFromUpload.');
        }

        if (uploadId === null || uploadId === undefined) {
            throw new Error('Required parameter uploadId was null or undefined when calling createUploadFromUpload.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newName !== undefined && newName !== null) {
            queryParameters = queryParameters.set('newName', <any>newName);
        }
        if (newTeam !== undefined && newTeam !== null) {
            queryParameters = queryParameters.set('newTeam', <any>newTeam);
        }
        if (uploadId !== undefined && uploadId !== null) {
            queryParameters = queryParameters.set('uploadId', <any>uploadId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Upload>('post',`${this.basePath}/upload/uploads/from/upload`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUpload(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUpload(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUpload(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUpload(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUpload.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/upload/uploads/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download the attachment based on the public upload.
     * 
     * @param attachmentId attachmentId
     * @param token token
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadPublicAttachment(attachmentId: number, token: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
    public downloadPublicAttachment(attachmentId: number, token: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
    public downloadPublicAttachment(attachmentId: number, token: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
    public downloadPublicAttachment(attachmentId: number, token: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling downloadPublicAttachment.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling downloadPublicAttachment.');
        }



        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Resource>('get',`${this.basePath}/upload/uploads/public/${encodeURIComponent(String(token))}/attachments/${encodeURIComponent(String(attachmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download all uploaded files as a ZIP archive
     * 
     * @param zipToken zipToken
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadUploadedFilesZIP(zipToken: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public downloadUploadedFilesZIP(zipToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public downloadUploadedFilesZIP(zipToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public downloadUploadedFilesZIP(zipToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (zipToken === null || zipToken === undefined) {
            throw new Error('Required parameter zipToken was null or undefined when calling downloadUploadedFilesZIP.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/upload/uploads/zip/${encodeURIComponent(String(zipToken))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read all visible entities. If the user is not allowed to read any entity, an empty list will be returned.
     * 
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param limit limit
     * @param offset offset
     * @param team team
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUpload(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Upload>>;
    public getAllUpload(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Upload>>>;
    public getAllUpload(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Upload>>>;
    public getAllUpload(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (team !== undefined && team !== null) {
            queryParameters = queryParameters.set('team', <any>team);
        }

        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Upload>>('get',`${this.basePath}/upload/uploads/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getByCustomer
     * 
     * @param customerId customerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByCustomer(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UploadTemplateSummary>>;
    public getByCustomer(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UploadTemplateSummary>>>;
    public getByCustomer(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UploadTemplateSummary>>>;
    public getByCustomer(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getByCustomer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UploadTemplateSummary>>('get',`${this.basePath}/upload/uploads/byCustomerId/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a public profile picture for the specified upload token.
     * 
     * @param token token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicProfilePicture(token: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getPublicProfilePicture(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getPublicProfilePicture(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getPublicProfilePicture(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getPublicProfilePicture.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'image/jpeg'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/upload/uploads/public/${encodeURIComponent(String(token))}/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an entity based on the given id.
     * 
     * @param id id
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUpload(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<Upload>;
    public getUpload(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Upload>>;
    public getUpload(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Upload>>;
    public getUpload(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUpload.');
        }



        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Upload>('get',`${this.basePath}/upload/uploads/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an upload based on the given token (used for public access).
     * 
     * @param token token
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param secret secret
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadByToken(token: string, ifModifiedSince?: Date, ifNoneMatch?: string, secret?: string, observe?: 'body', reportProgress?: boolean): Observable<Upload>;
    public getUploadByToken(token: string, ifModifiedSince?: Date, ifNoneMatch?: string, secret?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Upload>>;
    public getUploadByToken(token: string, ifModifiedSince?: Date, ifNoneMatch?: string, secret?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Upload>>;
    public getUploadByToken(token: string, ifModifiedSince?: Date, ifNoneMatch?: string, secret?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getUploadByToken.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (secret !== undefined && secret !== null) {
            queryParameters = queryParameters.set('secret', <any>secret);
        }

        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Upload>('get',`${this.basePath}/upload/uploads/public/${encodeURIComponent(String(token))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an overview of all uploads and templates available to the team or (if unset) user.
     * 
     * @param teamId teamId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadsOverview(teamId?: number, observe?: 'body', reportProgress?: boolean): Observable<UploadsOverview>;
    public getUploadsOverview(teamId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadsOverview>>;
    public getUploadsOverview(teamId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadsOverview>>;
    public getUploadsOverview(teamId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamId !== undefined && teamId !== null) {
            queryParameters = queryParameters.set('teamId', <any>teamId);
        }

        let headers = this.defaultHeaders;

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UploadsOverview>('get',`${this.basePath}/upload/uploads/overview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given data.
     * 
     * @param body data
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overwriteUpload(body: Upload, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Upload>;
    public overwriteUpload(body: Upload, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Upload>>;
    public overwriteUpload(body: Upload, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Upload>>;
    public overwriteUpload(body: Upload, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling overwriteUpload.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling overwriteUpload.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Upload>('put',`${this.basePath}/upload/uploads/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given modifications. The modified entity is returned.
     * 
     * @param body modifications
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUpload(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Upload>;
    public patchUpload(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Upload>>;
    public patchUpload(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Upload>>;
    public patchUpload(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchUpload.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchUpload.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Upload>('patch',`${this.basePath}/upload/uploads/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
