import {MatPaginatorIntl} from '@angular/material/paginator';

export const germanPaginator = new MatPaginatorIntl();
germanPaginator.nextPageLabel = 'Nächste Seite';
germanPaginator.previousPageLabel = 'Vorherige Seite';
germanPaginator.firstPageLabel = 'Erste Seite';
germanPaginator.lastPageLabel = 'Letzte Seite';
germanPaginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 von ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} von ${length}`;
};
