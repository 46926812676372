/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { User } from './user';

export interface Modification { 
    author?: User;
    created?: Date;
    eTag?: string;
    fieldName?: string;
    id?: number;
    newValue?: string;
    oldValue?: string;
    operation?: Modification.OperationEnum;
    updated?: Date;
    version?: number;
}
export namespace Modification {
    export type OperationEnum = 'DELETE' | 'REPLACE' | 'APPEND' | 'REMOVE';
    export const OperationEnum = {
        DELETE: 'DELETE' as OperationEnum,
        REPLACE: 'REPLACE' as OperationEnum,
        APPEND: 'APPEND' as OperationEnum,
        REMOVE: 'REMOVE' as OperationEnum
    };
}