<h4>
  <mat-icon>error</mat-icon>
  Sie verwenden einen veralteten Browser!
</h4>

<p>Internet Explorer ist ein veralteter Browser und weist Sicherheitslücken auf.<br>
  Unter Umständen stehen in diesem Browser einige Funktionen nur<br>
  eingeschränkt zur Verfügung.</p>

<p>Wir empfehlen Ihnen den Umstieg, auf einen aktuellen und sicheren Browser.</p>

<mat-divider></mat-divider>

<mat-nav-list>
  <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" mat-list-item (click)="ref.dismiss()">
    <span mat-line>Mozilla Firefox herunterladen</span>
    <span mat-line>Öffnet die Download-Seite von Mozilla Firefox in einem neuen Fenster</span>
  </a>
</mat-nav-list>
