<h1 mat-dialog-title>Ein Fehler ist aufgetreten</h1>

<div mat-dialog-content>
  <p>Die Fehlermeldung wurde an uns übermittelt. Wir werden uns so schnell wie möglich darum kümmern, den Fehler
    zu beheben.</p>

  <p>Falls Sie einen persönlichen Support im Zusammenhang mit diesem Fehler wünschen, nehmen Sie bitte via E-Mail
    Kontakt zu uns auf.</p>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="close()">Schließen</button>

  <a mat-button color="secondary" href="mailto:info@ap-software.de?subject=Supportanfrage zu Fehlermeldung"
     (click)="close()" class="support-btn">Supportanfrage senden</a>
</div>
