/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.5.0
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FieldModification } from '../model/fieldModification';
import { UserPreference } from '../model/userPreference';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserPreferenceControllerService {

    protected basePath = '//localhost/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a new entity based on the given data.
     * 
     * @param body data
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUserPreference(body: UserPreference, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<UserPreference>;
    public createUserPreference(body: UserPreference, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPreference>>;
    public createUserPreference(body: UserPreference, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPreference>>;
    public createUserPreference(body: UserPreference, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createUserPreference.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserPreference>('post',`${this.basePath}/common/preferences/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserPreference(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUserPreference(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUserPreference(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUserPreference(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUserPreference.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/common/preferences/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read all visible entities. If the user is not allowed to read any entity, an empty list will be returned.
     * 
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param limit limit
     * @param offset offset
     * @param team team
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUserPreferences(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<UserPreference>>;
    public getAllUserPreferences(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserPreference>>>;
    public getAllUserPreferences(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserPreference>>>;
    public getAllUserPreferences(ifModifiedSince?: Date, ifNoneMatch?: string, limit?: number, offset?: number, team?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (team !== undefined && team !== null) {
            queryParameters = queryParameters.set('team', <any>team);
        }

        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserPreference>>('get',`${this.basePath}/common/preferences/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an entity based on the given id.
     * 
     * @param id id
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserPreference(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<UserPreference>;
    public getUserPreference(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPreference>>;
    public getUserPreference(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPreference>>;
    public getUserPreference(id: number, ifModifiedSince?: Date, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUserPreference.');
        }



        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPreference>('get',`${this.basePath}/common/preferences/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read a preference based on the given id.
     * 
     * @param identifier identifier
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param fallback fallback
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getValue(identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, fallback?: string, observe?: 'body', reportProgress?: boolean): Observable<UserPreference>;
    public getValue(identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, fallback?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPreference>>;
    public getValue(identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, fallback?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPreference>>;
    public getValue(identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, fallback?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling getValue.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fallback !== undefined && fallback !== null) {
            queryParameters = queryParameters.set('fallback', <any>fallback);
        }
        if (identifier !== undefined && identifier !== null) {
            queryParameters = queryParameters.set('identifier', <any>identifier);
        }

        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPreference>('get',`${this.basePath}/common/preferences/value`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given data.
     * 
     * @param body data
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overwriteUserPreference(body: UserPreference, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<UserPreference>;
    public overwriteUserPreference(body: UserPreference, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPreference>>;
    public overwriteUserPreference(body: UserPreference, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPreference>>;
    public overwriteUserPreference(body: UserPreference, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling overwriteUserPreference.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling overwriteUserPreference.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserPreference>('put',`${this.basePath}/common/preferences/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given modifications. The modified entity is returned.
     * 
     * @param body modifications
     * @param id id
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUserPreference(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<UserPreference>;
    public patchUserPreference(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPreference>>;
    public patchUserPreference(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPreference>>;
    public patchUserPreference(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchUserPreference.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchUserPreference.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserPreference>('patch',`${this.basePath}/common/preferences/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read a preference based on the given id.
     * 
     * @param body data
     * @param identifier identifier
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setValue(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<UserPreference>;
    public setValue(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPreference>>;
    public setValue(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPreference>>;
    public setValue(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setValue.');
        }

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling setValue.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (identifier !== undefined && identifier !== null) {
            queryParameters = queryParameters.set('identifier', <any>identifier);
        }

        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserPreference>('post',`${this.basePath}/common/preferences/value`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read a preference based on the given id.
     * 
     * @param body data
     * @param identifier identifier
     * @param ifModifiedSince If-Modified-Since
     * @param ifNoneMatch If-None-Match
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setValue1(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<UserPreference>;
    public setValue1(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPreference>>;
    public setValue1(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPreference>>;
    public setValue1(body: UserPreference, identifier: string, ifModifiedSince?: Date, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setValue1.');
        }

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling setValue1.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (identifier !== undefined && identifier !== null) {
            queryParameters = queryParameters.set('identifier', <any>identifier);
        }

        let headers = this.defaultHeaders;
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserPreference>('put',`${this.basePath}/common/preferences/value`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param identifier identifier
     * @param ifMatch If-Match
     * @param ifUnmodifiedSince If-Unmodified-Since
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unsetValue(identifier: string, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unsetValue(identifier: string, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unsetValue(identifier: string, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unsetValue(identifier: string, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling unsetValue.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (identifier !== undefined && identifier !== null) {
            queryParameters = queryParameters.set('identifier', <any>identifier);
        }

        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (session) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["X-Session"]) {
            headers = headers.set('X-Session', this.configuration.apiKeys["X-Session"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/common/preferences/value`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
