import {NGXLoggerMonitor, NGXLogInterface} from 'ngx-logger';

export class GlobalLogListener extends NGXLoggerMonitor {

  static readonly latestLogs: NGXLogInterface[] = [];

  private readonly logsToKeep = 10;

  onLog(logObject: NGXLogInterface) {
    if (this.logsToKeep <= GlobalLogListener.latestLogs.length) {
      GlobalLogListener.latestLogs.splice(0, 1);
    }
    GlobalLogListener.latestLogs.push(logObject);
  }

}
