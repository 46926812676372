<h1 matDialogTitle>Buchung bestätigen</h1>

<p>Folgendes Paket wird gebucht:</p>

<ul [ngSwitch]="data.pkg.groupName">
  <li *ngSwitchCase="'package_group.upload'">
    Erhöhung maximale Uploads auf
    <strong>{{data.pkg.constraints.maxTotalUploads < 0 ? 'unbegrenzt' : data.pkg.constraints.maxTotalUploads}}</strong>
  </li>

  <li *ngSwitchCase="'package_group.ticket'">
    Erhöhung maximale Tickets auf
    <strong>{{data.pkg.constraints.maxTotalTickets < 0 ? 'unbegrenzt' : data.pkg.constraints.maxTotalTickets}}</strong>
  </li>

  <li *ngSwitchCase="'package_group.cloud'">
    Erhöhung Cloudspeicher auf
    <strong>{{data.pkg.constraints.maxMbStorage < 0 ? 'unbegrenzt' : data.pkg.constraints.maxMbStorage + ' MB'}}</strong>
  </li>
</ul>

<span class="package__pricing">
  {{data.pkg.actualPriceCents | price}}
  <ng-container *ngIf="data.pkg.billingType === 'MONTHLY'">/ Monat</ng-container>
  <ng-container *ngIf="data.pkg.billingType === 'ONCE'">einmalig</ng-container>
</span>

<div matDialogActions>
  <button mat-flat-button color="primary" (click)="book()">Jetzt buchen</button>
  <button mat-flat-button mat-dialog-close>Abbrechen</button>
</div>
