import {Component, Inject, OnInit} from '@angular/core';
import {SlackService} from '../slack.service';
import {ActivatedRoute} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private slackService: SlackService,
              private activatedRoute: ActivatedRoute,
              private logger: NGXLogger,
              @Inject('origin') private origin: string) {
  }

  ngOnInit(): void {
    this.logger.debug('User landed on 404');

    if (!this.origin.includes('localhost') && environment.production) {
      const url = this.activatedRoute.snapshot.url.join('/');
      let errorMsg = 'User ist im Frontend auf 404 gelandet: `/' + url + '`';
      errorMsg += '\nOrigin: ' + this.origin;
      errorMsg += '\nReferer: ' + (document.referrer || 'unbekannt/manueller Aufruf');
      this.slackService.sendSlackSupportMessage(errorMsg);
    }
  }

}
