import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {Message, MessageControllerService, Modification} from '@api';
import {CheckUnreadMessages, ReadMessage} from './messaging.actions';
import {tap} from 'rxjs/operators';

interface MessagingStateModel {
  messages: Message[] | null;
}

@State<MessagingStateModel>({
  name: 'messaging',
  defaults: {
    messages: null,
  },
})
@Injectable()
export class MessagingState {
  @Selector()
  static unreadMessages(state: MessagingStateModel): Message[] {
    return state?.messages ?? [];
  }

  constructor(private readonly messageApiService: MessageControllerService) {}

  @Action(CheckUnreadMessages)
  checkUnread(ctx: StateContext<MessagingStateModel>) {
    return this.messageApiService.getUnread().pipe(
      tap((messages: Message[]) => ctx.patchState({ messages })),
    );
  }

  @Action(ReadMessage)
  readMessage(ctx: StateContext<MessagingStateModel>, action: ReadMessage) {
    return this.messageApiService.patchMessage([
      {
        fieldName: 'read',
        operation: Modification.OperationEnum.REPLACE,
        value: new Date().toISOString()
      }
    ], action.messageId).pipe(
      tap((updatedMessage: Message) => {
        const messages = ctx.getState()?.messages ?? [];
        ctx.patchState({
          messages: messages.filter(message => message.id !== updatedMessage.id)
        });
      })
    );
  }
}
