import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BookingControllerService, UserPackage} from '@api';

export interface ConfirmBookPackageDialogData {
  pkg: UserPackage;
  userId: number;
}

@Component({
  selector: 'app-confirm-book-package-dialog',
  templateUrl: './confirm-book-package-dialog.component.html',
  styleUrls: ['./confirm-book-package-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmBookPackageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmBookPackageDialogData,
    private dialogRef: MatDialogRef<ConfirmBookPackageDialogComponent>,
    private bookingApiService: BookingControllerService
  ) {}

  book(): void {
    this.bookingApiService.createBookedUserPackage({
      pkgNames: [this.data.pkg.name],
      startDate: new Date(),
      userId: this.data.userId
    }).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
